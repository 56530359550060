import Login from './Login'
import Logout from './Logout'
import { authRoles } from 'auth'

export const LoginConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false
        },
        toolbar: {
          display: false
        },
        footer: {
          display: false
        },
        leftSidePanel: {
          display: false
        },
        rightSidePanel: {
          display: false
        }
      }
    }
  },
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: process.env.PUBLIC_URL + '/login',
      component: Login
    },
    {
      path: process.env.PUBLIC_URL + '/logout',
      component: Logout
    }
  ]
}
