import React from 'react'
import { withStyles } from '@material-ui/core/styles/index'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    '& .logo-text, & .react-badge': {
      transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.shortest,
        easing: theme.transitions.easing.easeInOut
      })
    }
  },
  logo: {},
  logoIcon: {
    width: 128,
    height: 128,
    transition: theme.transitions.create(['width', 'height'], {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut
    })
  },
  reactBadge: {
    backgroundColor: 'rgba(0,0,0,0.6)',
    color: '#61dafb'
  }
})

function MainNavbarHeader({ classes }) {
  return (
    <div className={classes.root}>
      <div className={classNames(classes.logo, 'flex items-center')}>
        <img width="128" src="assets/images/logos/ag-logo_500x500.png" alt="logo" />
      </div>
    </div>
  )
};

export default withStyles(styles, { withTheme: true })(withRouter(MainNavbarHeader))
