import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import * as authActions from 'auth/store/actions'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'

class Logout extends PureComponent {
  state = {
    tabValue: 0
  };

  componentDidMount = () => {
    this.props.logout()
  }

  render = () => {
    return (
      <div className="flex flex-col flex-1 flex-no-shrink p-24 md:flex-row md:p-0"></div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    logout: authActions.logoutUser
  }, dispatch)
}

function mapStateToProps() {
  return {}
}

export default (connect(mapStateToProps, mapDispatchToProps)(withRouter(Logout)))
