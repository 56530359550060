import axios from 'axios/index'
import { showMessage } from 'store/actions/fuse'
import config from 'config'

export const GET_BRAND = '[E-COMMERCE APP] GET BRAND'
export const SAVE_BRAND = '[E-COMMERCE APP] SAVE BRAND'
export const DELETE_BRAND = '[E-COMMERCE APP] DELETE BRAND'

export function getBrand(id) {
  const request = axios.get(`${config.endpoints.bff}/v1/brands/${id}`)

  return (dispatch) =>
    request
      .then((response) =>
        dispatch({
          type: GET_BRAND,
          payload: response.data
        })
      )
}

export function deleteBrand(id) {
  const request = axios.delete(`${config.endpoints.bff}/v1/brands/${id}`)

  return (dispatch) => {
    return request
      .then((response) =>
        dispatch({
          type: DELETE_BRAND,
          payload: response.data
        })
      )
  }
}


const stringToSlug = (text) => {
  text = text.replace(/^\s+|\s+$/g, ''); 
  text = text.toLowerCase();

    text = text.replace(/[^a-z0-9 -]/g, '')
        .replace(/\s+/g, '-') 
        .replace(/-+/g, '-');

    return text;
}

const makeBrandLogoSlug = (name, formatImage) => {
  const time = (new Date()).getTime()
  if (name && formatImage){
    const format = formatImage.split('.')
    return stringToSlug(name) + "_" + time + "." + format[format.length -1]
  } else {
    return {}
  }
}

export function saveBrand(data) {
  return (dispatch) => {
    
      let request = uploadImage(data.brandImage, makeBrandLogoSlug(data.name, data.brandImage && data.brandImage.name))
      delete data.brandImage

      return request.then(async (image) => {
        let response
        data.image = image && image.data
        data.logo = image && image.data && image.data.url

        if (data._id) {
          response = await axios.put(`${config.endpoints.bff}/v1/brands/${data._id}`, data)
          dispatch(showMessage({ message: 'Marca actualizada' }))
        } else {
          response = await axios.post(`${config.endpoints.bff}/v1/brands`, data)
          dispatch(showMessage({ message: 'Marca creada' }))
        }

        return dispatch({
          type: SAVE_BRAND,
          payload: Object.assign(data, response.data)
        })
      }
    )
  }
}

async function uploadImage(file,fileName) {
  if (file) {
    const form = new FormData()
    form.append('file', file, fileName)
    return axios.post(`${config.endpoints.bff}/v1/brands/image`, form)
  } else {
    return {}
  }
}

export function newBrand() {
  const data = {
    name: '',
    vendors: []
  }

  return {
    type: GET_BRAND,
    payload: data
  }
}
