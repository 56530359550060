import * as Actions from '../actions'

const initialState = {
  data: [],
  count: 0,
  searchText: '',
  page: 0,
  limit: 10
}

const categoriesReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_CATEGORIES:
      {
        return {
          ...state,
          data: action.payload,
          page: action.page,
          limit: action.limit
        }
      }
    case Actions.COUNT_CATEGORIES:
      {
        return {
          ...state,
          count: action.count
        }
      }
    case Actions.SEARCH_CATEGORIES:
      {
        return {
          ...state,
          searchText: action.query,
          data: action.payload
        }
      }
    case Actions.GET_ALL_CATEGORIES:
      {
        return {
          ...state,
          data: action.payload
        }
      }
    case Actions.SET_CATEGORIES_SEARCH_TEXT:
      {
        return {
          ...state,
          searchText: action.searchText
        }
      }
    case Actions.GET_COUNTRIES:
        {
          return {
            ...state,
            countries: action.payload,
          }
        }
    default:
      {
        return state
      }
  }
}

export default categoriesReducer
