import axios from 'axios/index'
import config from 'config'

export const GET_PRODUCTS = '[INVENTORY] GET PRODUCTS'
export const COUNT_PRODUCTS = '[INVENTORY] COUNT PRODUCTS'
export const DELETE_PRODUCTS = '[INVENTORY] DELETE PRODUCTS'
export const SEARCH_PRODUCTS = '[INVENTORY] SEARCH PRODUCTS'
export const LIST_PRICES = '[INVENTORY] LIST PRICES'
export const SET_PRODUCTS_SEARCH_TEXT = '[INVENTORY] SET PRODUCTS SEARCH TEXT'
export const GET_DOWNLOAD_MASTER = '[INVENTORY] GET DOWNLOAD MASTER'
export const GET_SOLDOUT_MASTER = '[INVENTORY] GET SOLDOUT MASTER'
export const GET_CATEGORIES_CSV = '[INVENTORY] GET CATEGORIES CSV'
export const GET_VENDORS_CSV = '[INVENTORY] GET VENDORS CSV'
export const GET_BRANDS_CSV = '[INVENTORY] GET BRANDS CSV'

export function getBrandsCsv(callback) {
  return function (dispatch) {
    const request = axios.get(`${config.endpoints.bff}/v1/brands/csv`)
    return request.then(response => {
      downloadURI(response.data.url, 'marcas.csv') 
      if (callback()) {
        callback()     
        dispatch({
          type: GET_BRANDS_CSV
        })       
      }
    });
  }
}

export function getCategoriesCsv(callback) {
  return function (dispatch) {

    const request = axios.get(`${config.endpoints.bff}/v1/categories/csv`)
    return request.then(response => {
      downloadURI(response.data.url, 'categorias.csv')

      if (callback) {
        callback()
        dispatch({
          type: GET_CATEGORIES_CSV
        })
      }
    });
  }
}

export function getVendorsCsv(callback) {
  return function (dispatch) {    
    const request = axios.get(`${config.endpoints.bff}/v1/vendors/csv`)
    return request.then(response => {
      downloadURI(response.data.url, 'proveedores.csv')

      if (callback) {
        callback()
        dispatch({
          type: GET_VENDORS_CSV
        })
      }
    });
  }
}


export function getSoldoutMaster(callback) {
  return function (dispatch) {
    const vendorId = localStorage.getItem('vendor')
    const request = axios.get(`${config.endpoints.bff}/v1/products/stock/download?providerId=${vendorId}`)
    return request.then(response => {
      downloadURI(response.data.url, 'productos_agotados.csv')

      if (callback) {
        callback()
        dispatch({
          type: GET_SOLDOUT_MASTER
        })
      }
    });
  }
}

export function getDownloadMaster (callback) {
  return function (dispatch) {
    const roleOfTheUser = localStorage.getItem('roleUser')
    const vendorId = localStorage.getItem('vendor')
    const vendorIdForAdmin = localStorage.getItem('providerId')

    if ((vendorId !== null && roleOfTheUser === "Super administrador") || (roleOfTheUser === "Administrador" && vendorIdForAdmin !== undefined)) {

      const request = roleOfTheUser === "Super administrador" && vendorId ?
        axios.get(`${config.endpoints.bff}/v1/products/downloadMaster/${vendorId}`)
        : axios.get(`${config.endpoints.bff}/v1/products/downloadMaster/${vendorIdForAdmin}`)

      return request.then(response => {
        const urlAsArray = response.data.split("/")
        downloadURI(response.data, urlAsArray[urlAsArray.length - 1])

        if (callback) {
          callback()
          dispatch({
            type: GET_DOWNLOAD_MASTER
          })
        }
      });
    } else {

      const request = axios.get(`${config.endpoints.bff}/v1/products/downloadMaster`)
      return request.then(response => {
        const urlAsArray = response.data.split("/")
        downloadURI(response.data, urlAsArray[urlAsArray.length - 1])

        if (callback) {
          callback()
          dispatch({
            type: GET_DOWNLOAD_MASTER
          })
        }
      });
    }
  }
}

function downloadURI(uri, name) {
  let link = document.createElement("a");
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  link = undefined;
}

export function exportTypeForWork() {
  return {
    type: GET_DOWNLOAD_MASTER,
  };
}

export function getProducts (page, rows) {
  return (dispatch, getState) => {
    const products = getState().products.products
    const query = products.searchText || ''
    const request = axios.get(`${config.endpoints.bff}/v1/products`, {
      params: {
        filter: JSON.stringify({
          where: {
            '$or': [
              {
                sku: {
                  '$regex': query,
                  '$options': 'ig'
                }
              },
              {
                name: {
                  '$regex': query,
                  '$options': 'ig'
                }
              },
              {
                externalId: {
                  '$regex': query,
                  '$options': 'ig'
                }
              }
            ]
          },
          skip: page * rows,
          limit: rows
        })
      }
    })
    return request.then((response) =>
      dispatch({
        type: GET_PRODUCTS,
        payload: response.data,
        page: page,
        limit: rows
      })
    )
  }
}

export function getCountProducts () {
  return (dispatch, getState) => {
    const products = getState().products.products
    const queries = (products.searchText || '').split(',')
    const request = axios.get(`${config.endpoints.bff}/v1/products/count`, {
      params: {
        filter: JSON.stringify({
          where: {
            '$or': []
              .concat(queries.map(q => ({
                sku: {
                  '$regex': q,
                  '$options': 'ig'
                }
              })))
              .concat(queries.map(q => ({
                name: {
                  '$regex': q,
                  '$options': 'ig'
                }
              })))
              .concat(queries.map(q => ({
                externalId: {
                  '$regex': q,
                  '$options': 'ig'
                }
              })))
          }
        })
      }
    })
    return request.then((response) =>
      dispatch({
        type: COUNT_PRODUCTS,
        count: response.data.count
      })
    )
  }
}

export function searchProducts (query) {
  return (dispatch, getState) => {
    const products = getState().products.products
    const queries = query.split(',')
    const { page, limit } = products
    const request = axios.get(`${config.endpoints.bff}/v1/products`, {
      params: {
        filter: JSON.stringify({
          where: {
            '$or': []
              .concat(queries.map(q => ({
                sku: {
                  '$regex': q,
                  '$options': 'ig'
                }
              })))
              .concat(queries.map(q => ({
                name: {
                  '$regex': q,
                  '$options': 'ig'
                }
              })))
              .concat(queries.map(q => ({
                externalId: {
                  '$regex': q,
                  '$options': 'ig'
                }
              })))
          },
          skip: page * limit,
          limit: limit
        })
      }
    })
    return request.then((response) =>
      dispatch({
        type: SEARCH_PRODUCTS,
        payload: response.data,
        query: query
      })
    )
  }
}

export function deleteProducts (productsId) {
  const request = Promise.all(
    productsId.map(
      async id => await axios.delete(`${config.endpoints.bff}/v1/products/${id}`)
    )
  )
  return (dispatch) =>
    request.then(() =>
      dispatch({
        type: DELETE_PRODUCTS
      })
    )
}

export function activateProducts (productsId) {
  const request = Promise.all(
    productsId.map(
      async id => await axios.put(`${config.endpoints.bff}/v1/products/${id}`, {
        active: true
      })
    )
  )
  return (dispatch) =>
    request.then(() =>
      dispatch({
        type: DELETE_PRODUCTS
      })
    )
}

export function deactivateProducts (productsId) {
  const request = Promise.all(
    productsId.map(
      async id => await axios.put(`${config.endpoints.bff}/v1/products/${id}`, {
        active: false
      })
    )
  )
  return (dispatch) =>
    request.then(() =>
      dispatch({
        type: DELETE_PRODUCTS
      })
    )
}

export function getLists () {
  const request = axios.get(`${config.endpoints.bff}/v1/prices/list`, {
    params: {
      filter: JSON.stringify({
        where: {}
      })
    }
  })
  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: LIST_PRICES,
        payload: response.data
      })
    )
}

export function setProductsSearchText (event) {
  return {
    type: SET_PRODUCTS_SEARCH_TEXT,
    searchText: event.target.value
  }
}
