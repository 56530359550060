import axios from 'axios'
import config from 'config'

export const GET_VENDORS = '[INVENTORY] GET VENDORS'
export const CHANGE_VENDOR = '[INVENTORY] CHANGE VENDORS'
export const SET_VENDORS_SEARCH_TEXT = '[INVENTORY] SET VENDORS SEARCH TEXT'
export const GET_COUNTRIES = '[INVENTORY] GET COUNTRIES'
export const CHANGE_COUNTRY = '[INVENTORY] CHANGE COUNTRY'
export function getVendors() {
  const request = axios.get(`${config.endpoints.bff}/v1/vendors`)

  return (dispatch) =>
    request.then((response) => {
      const vendors = response.data.sort(
        (a,b)=> {
          if (a.name < b.name) return -1;            
          if (a.name > b.name) return 1;
          return 0; 
      });
      return dispatch({
      type: GET_VENDORS,
      payload: vendors
      })
    }
  );
}

export function changeVendor(vendor) {
  return (dispatch) =>
    dispatch({
      type: CHANGE_VENDOR,
      vendor: vendor !== '' && vendor
    })
}
export function getCountries(){
  const request = axios.get(`${config.endpoints.bff}/v1/countries`)
  return (dispatch) =>
    dispatch({
      type: GET_COUNTRIES,
      payload: request.response.data
    })
}

export function changeCountry(countryCode) {
  return (dispatch) =>
    dispatch({
      type: CHANGE_COUNTRY,
      countryCode: countryCode !== '' && countryCode
    })
}

export function setVendorsSearchText(event) {
  return {
    type: SET_VENDORS_SEARCH_TEXT,
    searchText: event.target.value
  }
}
