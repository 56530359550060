import { authRoles } from 'auth'
import { concat } from 'lodash'
import usersRoutes from './users'
import scopesRoutes from './scopes'
import rolesRoutes from './roles'
import activitiesRoutes from './activities'

export const UsersManagementAppConfig = {
  settings: {
    layout: {}
  },
  auth: authRoles.root,
  routes: concat(usersRoutes, scopesRoutes, rolesRoutes, activitiesRoutes)
}
