import axios from 'axios/index'
import config from 'config'

export const GET_PROMOTIONS = '[E-COMMERCE APP] GET PROMOTIONS'
export const SET_PROMOTIONS_SEARCH_TEXT = '[E-COMMERCE APP] SET PROMOTIONS SEARCH TEXT'

export function getPromotions(providerId) {
  if(providerId){
    const request = axios.get(`${config.endpoints.bff}/v1/promotions?type=PromotionVendor&providerId=${providerId}`)
    return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_PROMOTIONS,
        payload: response.data
      })
    )
  } else {
    const request = axios.get(`${config.endpoints.bff}/v1/promotions?type=Promotion,rest,Marketing,PromotionModal`)
    return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_PROMOTIONS,
        payload: response.data
      })
    )
  }
}

export function setPromotionsSearchText(event) {
  return {
    type: SET_PROMOTIONS_SEARCH_TEXT,
    searchText: event.target.value
  }
}
