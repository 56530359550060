import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

const Securitize = (props) => {
  const { auth, userRole } = props
  if (auth && (!auth.includes(userRole) || (userRole !== 'guest' && auth.length === 1 && auth.includes('guest')))) {
    return null
  }
  return React.cloneElement(props.children, {
    style: {
      ...props.children.style
    }
  })
}

function mapStateToProps({ auth }) {
  return {
    userRole: auth.user.role && auth.user.role.alias
  }
}

export default (withRouter(connect(mapStateToProps)(Securitize)))
