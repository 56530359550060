import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { FuseNavigation, FuseLayouts } from "@fuse";
import { bindActionCreators } from "redux";
import * as FuseActions from "store/actions";
import withReducer from "store/withReducer";
import reducer from "main/content/inventory/store/reducers";
import { connect } from "react-redux";
import { get, set } from "lodash";

const styles = (theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
});

class MainNavbar extends PureComponent {
  componentDidUpdate = () => {
    let navigation = this.props.navigation;
    let change = false;
    if (this.props.request) {
      let menus = get(navigation, "0.children");
      menus = menus.map((menu) => {
        if (
          menu.id === "stores" &&
          this.props.request &&
          this.props.request.length > 0
        ) {
          if (
            !menu.badge ||
            (menu.badge &&
              this.props.request &&
              menu.badge.title !== this.props.request.length)
          ) {
            if (this.props.request && this.props.request.length > 0) {
              menu.badge = {
                title: this.props.request.length,
                bg: "rgb(3, 155, 228)",
                fg: "#FFFFFF",
              };
            } else {
              menu.badge = {
                title: 0,
                bg: "rgb(3, 155, 228)",
                fg: "#FFFFFF",
              };
            }
            change = true;
          }
        } else if (menu.badge) {
          menu.badge = null;
          change = true;
        }
        return menu;
      });
      setTimeout(() => {
        if (change) {
          navigation = set(navigation, "0.children", menus);
          this.props.setNavigation(navigation);
        }
      }, 1000);
    }
  };

  render = () => {
    const { classes, navigation, layoutStyle } = this.props;
    const navigationLayout = FuseLayouts[layoutStyle].type;
    return (
      <div className={classes.root}>
        {navigationLayout === "vertical" ? (
          <React.Fragment>
            <FuseNavigation navigation={navigation} layout={navigationLayout} />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <FuseNavigation navigation={navigation} layout={navigationLayout} />
          </React.Fragment>
        )}
      </div>
    );
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getNavigation: FuseActions.getNavigation,
      setNavigation: FuseActions.setNavigation,
    },
    dispatch
  );
}

function mapStateToProps({ fuse, auth, stores }) {
  return {
    navigation: fuse.navigation,
    layoutStyle: fuse.settings.current.layout.style,
  };
}

export default withReducer(
  "stores",
  reducer
)(
  withStyles(styles, { withTheme: true })(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(MainNavbar))
  )
);
