import React from 'react'
import { Icon, ListItem, ListItemText, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles/index'
import { NavLink, withRouter } from 'react-router-dom'
import classNames from 'classnames'
import FuseNavBadge from './../FuseNavBadge'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as Actions from 'store/actions'

const propTypes = {
  item: PropTypes.shape(
    {
      id: PropTypes.string.isRequired,
      title: PropTypes.string,
      icon: PropTypes.string,
      url: PropTypes.string
    })
}

const defaultProps = {}

const styles = theme => ({
  item: {
    height: 50,
    width: '100%',
    '&.active': {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.secondary.contrastText + '!important',
      pointerEvents: 'none',
      fontWeight: 500,
      borderLeft: '3px solid ' + theme.palette.secondary.contrastText,
      '& .list-item-text-primary': {
        color: 'inherit'
      },
      '& .list-item-icon': {
        color: 'inherit'
      }
    },
    '&.square, &.active.square': {
      width: '100%',
      borderRadius: '0'
    },
    '& .list-item-icon': {},
    '& .list-item-text': {},
    color: theme.palette.secondary.contrastText + '!important',
    textDecoration: 'none!important'
  },
  element: {
    color: 'rgba(255, 255, 255, 0.76) !important',
    fontWeight: 100,
    marginRight: 15
  }
})

function FuseNavVerticalItem({ item, classes, userRole, navbarCloseMobile, active }) {
  if (item.auth && (!item.auth.includes(userRole) || (userRole !== 'guest' && item.auth.length === 1 && item.auth.includes('guest')))) {
    return null
  }

  return (
    <ListItem
      button
      component={NavLink}
      to={item.url}
      activeClassName="active"
      className={classNames(classes.item, 'list-item', active)}
      onClick={navbarCloseMobile}
      exact={item.exact}
    >
      {item.icon && (
        <Icon className={classNames(classes.element, 'list-item-icon', 'text-16', 'flex-no-shrink')} color="action">{item.icon}</Icon>
      )}
      <ListItemText className="list-item-text" primary={
        <React.Fragment>
          <Typography component="span" className={classNames(classes.element, 'text-16', 'list-item-text-primary')} color="textPrimary">{item.title}</Typography>
        </React.Fragment>
      } />
      {item.badge && (
        <FuseNavBadge badge={item.badge} />
      )}
    </ListItem>
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    navbarCloseMobile: Actions.navbarCloseMobile
  }, dispatch)
}

function mapStateToProps({ auth, fuse }) {
  return {
    userRole: auth.user.role && auth.user.role.alias
  }
}

FuseNavVerticalItem.propTypes = propTypes
FuseNavVerticalItem.defaultProps = defaultProps

const NavVerticalItem = withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(FuseNavVerticalItem)))

export default NavVerticalItem
