import * as Actions from '../actions'

const initialState = {
  data: [],
  searchText: ''
}

const promotionsReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_PROMOTIONS: {
      return {
        ...state,
        data: action.payload
      }
    }
    case Actions.SET_PROMOTIONS_SEARCH_TEXT: {
      return {
        ...state,
        searchText: action.searchText
      }
    }
    default: {
      return state
    }
  }
}

export default promotionsReducer
