import { FuseLoadable } from '@fuse'
import { authRoles } from 'auth'

export const UploadsAppConfig = {
  settings: {
    layout: {}
  },
  auth: authRoles.user,
  routes: [
    {
      path: process.env.PUBLIC_URL + '/uploads',
      component: FuseLoadable({
        loader: () => import('./upload/Upload')
      })
    }
  ]
}
