import { FuseLoadable } from '@fuse'

export default [
  {
    path: process.env.PUBLIC_URL + '/users-management/scopes/:scopeId',
    component: FuseLoadable({
      loader: () => import('./scope/Scope')
    })
  },
  {
    path: process.env.PUBLIC_URL + '/users-management/scopes',
    component: FuseLoadable({
      loader: () => import('./list/ScopesList')
    })
  }
]
