import axios from 'axios/index'
import config from 'config'

export const GET_CATEGORIES = '[INVENTORY] GET CATEGORIES'
export const COUNT_CATEGORIES = '[INVENTORY] COUNT CATEGORIES'
export const DELETE_CATEGORIES = '[INVENTORY] DELETE CATEGORIES'
export const SEARCH_CATEGORIES = '[INVENTORY] SEARCH CATEGORIES'
export const OPEN_DIALOG_CATEGORY = '[INVENTORY] OPEN DIALOG CATEGORY'
export const SET_CATEGORIES_SEARCH_TEXT = '[INVENTORY] SET CATEGORIES SEARCH TEXT'
export const GET_ALL_CATEGORIES = '[INVENTORY] GET CATEGORIES'
export const GET_COUNTRIES = '[INVENTORY] GET COUNTRIES'

export function getCategories (page, rows) {
  return (dispatch, getState) => {
    const categories = getState().categories.categories
    const query = categories.searchText || ''
    const request = axios.get(`${config.endpoints.bff}/v1/categories/byfilter`, {
      params: {
        filter: JSON.stringify({
          where: {
            '$or': [
              {
                sku: {
                  '$regex': query,
                  '$options': 'ig'
                }
              },
              {
                name: {
                  '$regex': query,
                  '$options': 'ig'
                }
              },
              {
                externalId: {
                  '$regex': query,
                  '$options': 'ig'
                }
              }
            ]
          },
          skip: page * rows,
          limit: rows
        })
      }
    })
    return request.then((response) =>
      dispatch({
        type: GET_CATEGORIES,
        payload: response.data,
        page: page,
        limit: rows
      })
    )
  }
}

export function getCountCategories () {
  return (dispatch, getState) => {
    const categories = getState().categories.categories
    const queries = (categories.searchText || '').split(',')
    const request = axios.get(`${config.endpoints.bff}/v1/categories/count`, {
      params: {
        filter: JSON.stringify({
          where: {
            '$or': []
              .concat(queries.map(q => ({
                sku: {
                  '$regex': q,
                  '$options': 'ig'
                }
              })))
              .concat(queries.map(q => ({
                name: {
                  '$regex': q,
                  '$options': 'ig'
                }
              })))
              .concat(queries.map(q => ({
                externalId: {
                  '$regex': q,
                  '$options': 'ig'
                }
              })))
          }
        })
      }
    })
    return request.then((response) =>
      dispatch({
        type: COUNT_CATEGORIES,
        count: response.data.count
      })
    )
  }
}

export function searchCategories (query) {
  return (dispatch, getState) => {
    const categories = getState().categories.categories
    const queries = query.split(',')
    const { page, limit } = categories
    const request = axios.get(`${config.endpoints.bff}/v1/categories/byfilter`, {
      params: {
        filter: JSON.stringify({
          where: {
            '$or': []
              .concat(queries.map(q => ({
                sku: {
                  '$regex': q,
                  '$options': 'ig'
                }
              })))
              .concat(queries.map(q => ({
                name: {
                  '$regex': q,
                  '$options': 'ig'
                }
              })))
              .concat(queries.map(q => ({
                externalId: {
                  '$regex': q,
                  '$options': 'ig'
                }
              })))
          },
          skip: page * limit,
          limit: limit
        })
      }
    })
    return request.then((response) =>
      dispatch({
        type: SEARCH_CATEGORIES,
        payload: response.data,
        query: query
      })
    )
  }
}

export function getAllCategories () {
  return (dispatch) => {
    const request = axios.get(`${config.endpoints.bff}/v2/categories`)
    return request.then((response) =>
      dispatch({
        type: GET_ALL_CATEGORIES,
        payload: response.data,
      })
    )
  }
}

export function getCountries () {
  return (dispatch) => {
    const request = axios.get(`${config.endpoints.bff}/v1/countries`)
    return request.then((response) =>
      dispatch({
        type: GET_COUNTRIES,
        payload: response.data,
      })
    )
  }
}

export function deleteCategories (categoriesId) {
  const request = Promise.all(
    categoriesId.map(
      async id => await axios.delete(`${config.endpoints.bff}/v1/categories/${id}`)
    )
  )
  return (dispatch) =>
    request.then(() =>
      dispatch({
        type: DELETE_CATEGORIES
      })
    )
}

export function setCategoriesSearchText (event) {
  return {
    type: SET_CATEGORIES_SEARCH_TEXT,
    searchText: event.target.value
  }
}
