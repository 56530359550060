import jwtService from 'jwtService'
import { setUserData } from 'auth/store/actions/user.actions'

export const LOGIN_ERROR = 'LOGIN_ERROR'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'

export function submitLogin ({ email, password }) {
  return (dispatch) => new Promise(async (resolve, reject) => {
    try {
      const user = await jwtService.signInWithEmailAndPassword(email, password)
      dispatch(setUserData(user))
      resolve(user)
      return dispatch({
        type: LOGIN_SUCCESS
      })
    } catch (error) {
      reject(error)
      return dispatch({
        type: LOGIN_ERROR,
        payload: error
      })
    }
  })
}
