export const isNumeric = (val) => {
  if (!val) return false
  const numericRegex  = /^\d+$/;
  return numericRegex.test(val)
}

export const isAlphanumeric = (val) => {
  if (!val) return false
  const alphanumericRegex  = /^[a-z0-9]+$/i;
  return alphanumericRegex.test(val)
}

export const validateLocalstorageString = (string) => {
  return Boolean(string && string !== 'false' && string !== 'undefined' && string !== 'null')
}