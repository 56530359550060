import axios from 'axios'
import { validateLocalstorageString } from '../../../../../utils/strings'
import * as Actions from '../actions'

const initialState = {
  data: [],
  searchText: '',
  vendor: window.localStorage.getItem('vendor') || '',
  countries: []
}

const vendorsReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_VENDORS:
      {
        return {
          ...state,
          data: action.payload
        }
      }
    case Actions.CHANGE_VENDOR:
      {
        window.localStorage.setItem('vendor', action.vendor)
        return {
          ...state,
          vendor: action.vendor
        }
      }
    case Actions.SET_VENDORS_SEARCH_TEXT:
      {
        return {
          ...state,
          searchText: action.searchText
        }
      }
    case Actions.GET_COUNTRIES:
      {
        return {
          ... state,
          countries: action.payload
        }
      }
    case Actions.CHANGE_COUNTRY:
      {
        return {
          ... state,
          countryCode: action.countryCode
        }
      }
    default:
      {
        return state
      }
  }
}

axios.interceptors.request.use(request => {
  const vendor = window.localStorage.getItem('vendor') || false
  if (validateLocalstorageString(vendor)) {
    if (request.method === 'get') {
      if (request.params && request.params.filter) {
        if (typeof request.params.filter === 'string') {
          request.params.filter = JSON.parse(request.params.filter)
        }
        if (!request.params.filter.where) {
          request.params.filter.where = {}
        }
        request.params.filter.where = Object.assign(request.params.filter.where, {
          providerId: window.localStorage.getItem('vendor')
        })
      }
    } else {
      const params = new URLSearchParams();
      params.append('providerId', vendor);
      request.params = params
    }
  }
  return request
}, err => {
})

axios.interceptors.request.use(config => {
  const selectedCountry = localStorage.getItem('selectedCountry')

  if (selectedCountry && selectedCountry!=='DEFAULT') config.headers.countryCode =  selectedCountry  

  return config
})



export default vendorsReducer
