import axios from 'axios/index'
import config from 'config'

export const GET_BRANDS = '[E-COMMERCE APP] GET BRANDS'
export const SET_BRANDS_SEARCH_TEXT = '[E-COMMERCE APP] SET BRANDS SEARCH TEXT'

export function getBrands() {
  const request = axios.get(`${config.endpoints.bff}/v1/brands`)

  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_BRANDS,
        payload: response.data
      })
    )
}

export function setBrandsSearchText(event) {
  return {
    type: SET_BRANDS_SEARCH_TEXT,
    searchText: event.target.value
  }
}
