import * as Actions from '../actions'

const initialState = {
  data: [],
  dialogCreate: false,
  newDialogCreate: false
}

const categoryReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_CATEGORY:
      {
        return {
          ...state,
          data: action.payload
        }
      }
    case Actions.GET_BUSSINES_TYPE:
      {
        return {
          ...state,
          data: action.payload
        }
      }
    case Actions.SAVE_CATEGORY:
      {
        return {
          ...state,
          data: action.payload
        }
      }
    case Actions.MASS_MAP:
      {
        return {
          ...state,
          data: action.payload
        }
      }
    case Actions.OPEN_DIALOG_CATEGORY:
      {
        return {
          ...state,
          dialogCreate: action.dialogCreate,
          category: action.category
        }
      }
    case Actions.OPEN_NEW_DIALOG_CATEGORY:
      {
        return {
          ...state,
          newDialogCreate: action.newDialogCreate
        }
      }
    case Actions.GET_COUNTRIES:
      {
        return {
          ...action.payload
        }
      }
    default:
      {
        return state
      }
  }
}

export default categoryReducer
