import React from "react";
import { Redirect } from "react-router-dom";
import { FuseLoadable } from "@fuse";
import { authRoles } from "auth";

export const InventoryAppConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.user,
  routes: [
    {
      path: process.env.PUBLIC_URL + "/products/:productId/:listId",
      component: FuseLoadable({
        loader: () => import("./product/Product"),
      }),
    },
    {
      path: process.env.PUBLIC_URL + "/products",
      component: FuseLoadable({
        loader: () => import("./products/Products"),
      }),
    },
    {
      path: process.env.PUBLIC_URL + "/inventory",
      component: () => <Redirect to="/products" />,
    },
  ],
};

export const VendorsAppConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.root,
  routes: [
    {
      path: process.env.PUBLIC_URL + "/brands/:brandId",
      component: FuseLoadable({
        loader: () => import("./brand/Brand"),
      }),
    },
    {
      path: process.env.PUBLIC_URL + "/brands",
      component: FuseLoadable({
        loader: () => import("./brands/Brands"),
      }),
    },
    {
      path: process.env.PUBLIC_URL + "/categories/:productId",
      component: FuseLoadable({
        loader: () => import("./category/Category"),
      }),
    },
    {
      path: process.env.PUBLIC_URL + "/categories",
      component: FuseLoadable({
        loader: () => import("./categories/Categories"),
      }),
    },
    {
      path: process.env.PUBLIC_URL + "/coupons/:promoId",
      component: FuseLoadable({
        loader: () => import("./coupon/Coupon"),
      }),
    },
    {
      path: process.env.PUBLIC_URL + "/coupons",
      component: FuseLoadable({
        loader: () => import("./coupons/Coupons"),
      }),
    },
    {
      path: process.env.PUBLIC_URL + "/promotions/:promoId",
      component: FuseLoadable({
        loader: () => import("./promotion/Promotion"),
      }),
    },
    {
      path: process.env.PUBLIC_URL + "/promotions",
      component: FuseLoadable({
        loader: () => import("./promotions/Promotions"),
      }),
    },
    {
      path: process.env.PUBLIC_URL + "/vendors/:vendorId",
      component: FuseLoadable({
        loader: () => import("./vendor/Vendor"),
      }),
    },
    {
      path: process.env.PUBLIC_URL + "/vendors",
      component: FuseLoadable({
        loader: () => import("./vendors/Vendors"),
      }),
    },
    {
      path: process.env.PUBLIC_URL + "/blacklist-coupons",
      component: FuseLoadable({
        loader: () => import("./blacklist/Blacklist"),
      }),
    },
  ],
};
