import React from 'react'
import FuseNavVerticalCollapse from './FuseNavVerticalCollapse'
import FuseNavVerticalItem from './FuseNavVerticalItem'
import { withStyles } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

const propTypes = {
  item: PropTypes.shape(
    {
      id: PropTypes.string.isRequired,
      title: PropTypes.string,
      children: PropTypes.array
    })
}

const defaultProps = {}

const styles = theme => ({
  item: {
    height: 50,
    width: '100%'
  }
})

function FuseNavVerticalEnd({ item, nestedLevel, userRole, active }) {
  if (item.auth && (!item.auth.includes(userRole) || (userRole !== 'guest' && item.auth.length === 1 && item.auth.includes('guest')))) {
    return null
  }

  return (
    <React.Fragment>
      <div className='flex flex-1'></div>

      {item.children && (
        <React.Fragment>
          {
            item.children.map((item) => (

              <React.Fragment key={item.id}>

                {item.type === 'group' && (
                  <NavVerticalGroup item={item} nestedLevel={nestedLevel} active={active} />
                )}

                {item.type === 'collapse' && (
                  <FuseNavVerticalCollapse item={item} nestedLevel={nestedLevel} active={active} />
                )}

                {item.type === 'item' && (
                  <FuseNavVerticalItem item={item} nestedLevel={nestedLevel} active={active} />
                )}

              </React.Fragment>
            ))
          }
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

function mapStateToProps({ auth }) {
  return {
    userRole: auth.user.role && auth.user.role.alias
  }
}

FuseNavVerticalEnd.propTypes = propTypes
FuseNavVerticalEnd.defaultProps = defaultProps

const NavVerticalGroup = withStyles(
  styles, { withTheme: true })(withRouter(connect(mapStateToProps)(FuseNavVerticalEnd)))

export default NavVerticalGroup
