import React, { Component } from 'react'
import { matchRoutes } from 'react-router-config'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import _ from '@lodash'

let redirect = false
let count = 0
class FuseAuthorization extends Component {
  
  constructor(props) {
    super(props)
    this.checkAuth()
  }

  componentDidUpdate(prevProps) {
    /**
         * If route is changed
         * Update auths
         */

    count = count +1
    
    if (count < 5 && !_.isEqual(this.props.location.pathname, prevProps.location.pathname)) {
      this.checkAuth()
    }
  }

  checkAuth() {
    const matched = matchRoutes(this.props.routes, this.props.location.pathname)[0]
    if (matched && matched.route.auth && matched.route.auth.length > 0 && this.props.user && this.props.user.role) {
      if (!matched.route.auth.includes(this.props.user.role.alias)) {
        redirect = true
        if (this.props.user.role.alias === 'guest' || !this.props.user.role.alias) {
          this.props.history.push({
            pathname: process.env.PUBLIC_URL + '/login',
            state: { redirectUrl: this.props.location.pathname }
          })
        } else {
          this.props.history.push({
            pathname: '/'
          })
        }
      }
    } else {
      this.props.history.push({
        pathname: process.env.PUBLIC_URL + '/login',
        state: { redirectUrl: this.props.location.pathname }
      })
    }
  }

  shouldComponentUpdate(nextProps) {
    if (redirect) {
      redirect = false
      return false
    } else {
      return true
    }
  }

  render() {
    const { children } = this.props

    return (
      <React.Fragment>
        {children}
      </React.Fragment>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch)
}

function mapStateToProps({ fuse, auth }) {
  return {
    user: auth.user
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FuseAuthorization))
