import * as Actions from '../actions'

const initialState = null

const vendorReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_VENDOR:
      {
        return {
          ...action.payload
        }
    }
    case Actions.SAVE_VENDOR:
      {
        return {
          ...action.payload
        }
    }
    case Actions.UPDATE_VENDOR:
      {
        return {
          ...action.payload
        }
    }
    case Actions.GET_CITIES:
      {
        return {
          ...action.payload
        }
    }
    case Actions.GET_COMUNES:
    {
      return {
        ...action.payload
      }
    }
    case Actions.GET_PAYMENT_METHODS:
      {
        return {
          ...action.payload
        }
    }
    case Actions.GET_NO_MAPPED_CATEGORIES:
      {
        return {
          ...action.payload
        }
    }
    case Actions.UPDATE_CATEGORIES:
      {
        return {
          ...action.payload
        }
    }
    case Actions.GET_CATEGORIES:
      {
        return {
          ...action.payload
        }
    }
    case Actions.GET_COUNTRIES:
      {
        return {
          ...action.payload
        }
    }
    case Actions.GET_BRANDS:
      {
        return {
          ...action.payload
        }
    }
    case Actions.GET_NO_MAPPED_BRANDS:
      {
        return {
          ...action.payload
        }
    }
    case Actions.UPDATE_BRANDS:
      {
        return {
          ...action.payload
        }
    }
    case Actions.GET_BUSSINES_TYPE:
      {
        return {
          ...state,
          data: action.payload
        }
    }
    case Actions.CREATE_RIBBON:
      {
        return {
          ...state,
          data: action.payload
        }
    }
    case Actions.DELETE_RIBBON:
      {
        return {
          ...state,
          data: action.payload
        }
    }
    default:
      {
        return state
    }
  }
}

export default vendorReducer
