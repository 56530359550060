import React, { Component } from "react";
import {
  Button,
  CircularProgress,
  withStyles,
  InputAdornment,
  Icon
} from "@material-ui/core";
import { TextFieldFormsy } from "@fuse";
import Formsy from "formsy-react";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import * as authActions from "auth/store/actions";
import * as Actions from "store/actions";
import green from "@material-ui/core/colors/green";
import PropTypes from "prop-types";
import classNames from 'classnames'

const styles = () => ({
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  button1: {
    marginBottom: 20
  },
  wrapper: {
    position: "relative"
  }
});

class JWTLoginTab extends Component {
  state = {
    canSubmit: false,
    loading: false
  };

  form = React.createRef();

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  onSubmit = async model => {
    try {
      this.setState({
        loading: true,
        canSubmit: false
      });
      await this.props.submitLogin(model);
      this.setState({
        loading: false,
        canSubmit: true
      });
    } catch (e) {
      this.props.showMessage({ message: "Usuario o contraseña inválida" });
      this.setState({
        loading: false,
        canSubmit: true
      });
    }
  };

  componentDidUpdate() {
    if (
      this.props.login.error &&
      (this.props.login.error.email || this.props.login.error.password)
    ) {
      this.form.updateInputsWithError({
        ...this.props.login.error
      });

      this.props.login.error = null;
      this.disableButton();
      this.setState({
        loading: false,
        canSubmit: true
      });
    }

    return null;
  }

  render() {
    const { canSubmit, loading } = this.state;
    const { classes } = this.props;

    return (
      <div className="w-full">
        <Formsy
          onValidSubmit={this.onSubmit}
          onValid={this.enableButton}
          onInvalid={this.disableButton}
          ref={form => (this.form = form)}
          className="flex flex-col justify-center w-full"
        >
          <TextFieldFormsy
            className={classNames(classes.button1, "mb-16")}
            style={{ marginBottom: '20px' }}
            type="text"
            name="email"
            label="Usuario/Correo electrónico"
            validations={{
              minLength: 4
            }}
            validationErrors={{
              minLength: "Largo mínimo es 4"
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Icon className="text-20" color="action">
                    email
                  </Icon>
                </InputAdornment>
              )
            }}
            variant="outlined"
            required
            
          />

          <TextFieldFormsy
            className="mb-16"
            style={{ marginBottom: '20px' }}
            type="password"
            name="password"
            label="Contraseña"
            validations={{
              minLength: 4
            }}
            validationErrors={{
              minLength: "Largo mínimo es 4"
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Icon className="text-20" color="action">
                    vpn_key
                  </Icon>
                </InputAdornment>
              )
            }}
            variant="outlined"
            required
          />

          <div className={classes.wrapper}>
            <Button
              style={{ marginTop: 20 }}
              type="submit"
              variant="contained"
              color="primary"
              className="w-full mx-auto mt-16 normal-case"
              aria-label="LOG IN"
              disabled={!canSubmit}
              value="legacy"
            >
              Ingresar
            </Button>
            {loading && (
              <CircularProgress size={40} className={classes.buttonProgress} />
            )}
          </div>
        </Formsy>
      </div>
    );
  }
}

JWTLoginTab.propTypes = {
  classes: PropTypes.any,
  login: PropTypes.any,
  submitLogin: PropTypes.func
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      submitLogin: authActions.submitLogin,
      showMessage: Actions.showMessage
    },
    dispatch
  );
}

function mapStateToProps({ auth }) {
  return {
    login: auth.login,
    user: auth.user
  };
}

export default withStyles(styles, { withTheme: true })(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(JWTLoginTab))
);
