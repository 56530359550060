import axios from 'axios'
import config from 'config'
import { showMessage } from 'store/actions/fuse'

export const GET_VENDORS_BLACKLIST = '[INVENTORY] GET VENDORS IN BLACKLIST'
export const SAVE_IN_BLACKLIST = '[INVENTORY] PUSH INTO BLACKLIST'
export const DELETE_BLACKLIST = '[INVENTORY] DELETE BLACKLIST'
export const SET_VENDORS_SEARCH_TEXT = '[INVENTORY] SET VENDORS SEARCH TEXT'

export function getVendorsInBlackList() {
  const request = axios.get(`${config.endpoints.bff}/v1/blacklistvendors?typology=referal`)
  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_VENDORS_BLACKLIST,
        payload: response.data
      })
    );
}

export function pushIntoBlacklist (vendorId) {
  return (dispatch) => {
    const request = axios.post(`${config.endpoints.bff}/v1/blacklistvendors?typology=referal`, {
      vendorId
    })
    return request.then((response) => {
      dispatch(showMessage({ message: 'Agregado a la lista negra' }))

      return dispatch({
        type: SAVE_IN_BLACKLIST,
        payload: response.data
      })
    }
    )
  }
}

export function deleteBlacklist(vendorId) {
  const request = axios.delete(`${config.endpoints.bff}/v1/blacklistvendors/${vendorId}?typology=referal`)

  return (dispatch) => {
    return request
      .then((response) =>
        dispatch({
          type: DELETE_BLACKLIST,
          payload: response.data
        })
      )
  }
}

export function setVendorsSearchText(event) {
  return {
    type: SET_VENDORS_SEARCH_TEXT,
    searchText: event.target.value
  }
}
