const fuseDark = {
  50: '#FFF',
  100: '#FFF',
  200: '#FFF',
  300: '#FFF',
  400: '#FFF',
  500: '#FFF',
  600: '#FFF',
  700: '#FFF',
  800: '#FFF',
  900: '#FFF',
  A100: '#FFF',
  A200: '#FFF',
  A400: '#FFF',
  A700: '#FFF'
}

export default fuseDark
