import React from 'react'
import { Redirect } from 'react-router-dom'
import { FuseLoadable } from '@fuse'
import { authRoles } from 'auth'

export const OMSAppConfig = {
  settings: {
    layout: {}
  },
  auth: authRoles.user,
  routes: [
    {
      path: process.env.PUBLIC_URL + '/deliveries/:deliveryId',
      component: FuseLoadable({
        loader: () => import('./delivery/Delivery')
      })
    },
    {
      path: process.env.PUBLIC_URL + '/deliveries',
      component: FuseLoadable({
        loader: () => import('./deliveries/Deliveries')
      })
    },
    {
      path: process.env.PUBLIC_URL + '/carts/:deliveryId',
      component: FuseLoadable({
        loader: () => import('./cart/Cart')
      })
    },
    {
      path: process.env.PUBLIC_URL + '/carts',
      component: FuseLoadable({
        loader: () => import('./carts/Carts')
      })
    },
    {
      path: process.env.PUBLIC_URL + '/distributors/:distributorId',
      component: FuseLoadable({
        loader: () => import('./distributor/Distributor')
      })
    },
    {
      path: process.env.PUBLIC_URL + '/distributors',
      component: FuseLoadable({
        loader: () => import('./distributors/Distributors')
      })
    },
    {
      path: '/oms',
      component: () => <Redirect to="/orders" />
    }
  ]
}

export const DistributorOMSConfig = {
  settings: {
    layout: {}
  },
  auth: authRoles.distributor,
  routes: [
    {
      path: process.env.PUBLIC_URL + '/orders/:orderId',
      component: FuseLoadable({
        loader: () => import('./order/Order')
      })
    },
    {
      path: process.env.PUBLIC_URL + '/orders',
      component: FuseLoadable({
        loader: () => import('./orders/Orders')
      })
    }
  ]
}
