import { FuseLoadable } from '@fuse';
import { authRoles } from 'auth'

export const DashboardAppConfig = {
  settings: {
    layout: {
      config: {}
    }
  },
  auth: authRoles.distributor,
  routes: [
    {
      path: process.env.PUBLIC_URL + '/dashboard',
      component: FuseLoadable({
        loader: () => import('./DashboardApp')
      })
    }
  ]
};
