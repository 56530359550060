import axios from 'axios/index'
import config from 'config'

export const GET_COUPONS = '[E-COMMERCE APP] GET COUPONS'
export const SET_COUPONS_SEARCH_TEXT = '[E-COMMERCE APP] SET COUPONS SEARCH TEXT'

export function getCoupons() {
  const request = axios.get(`${config.endpoints.bff}/v1/coupons/all`)

  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_COUPONS,
        payload: response.data
      })
    )
}

export function setCouponsSearchText(event) {
  return {
    type: SET_COUPONS_SEARCH_TEXT,
    searchText: event.target.value
  }
}
