import axios from 'axios/index'
import { showMessage } from 'store/actions/fuse'
import config from 'config'

export const GET_BUSINESS_TYPES = '[E-COMMERCE APP] GET BUSINESS TYPES'
export const GET_COUPON = '[E-COMMERCE APP] GET COUPON'
export const SAVE_COUPON = '[E-COMMERCE APP] SAVE COUPON'
export const DELETE_COUPON = '[E-COMMERCE APP] DELETE COUPON'

const validCouponFields = ['users','discount_type','minimum_amount','couponType','code','date_expires', 'amount',
 'description', 'providerId', 'countryId', 'bussinesTypeId', 'providers'];

export function getCoupon(promoId) {
  const request = axios.get(`${config.endpoints.bff}/v1/coupons/findOne/${promoId}`)

  return (dispatch) =>
    request
      .then((response) =>
        dispatch({
          type: GET_COUPON,
          payload: response.data
        })
      )
}

export function deleteCoupon(id) {
  const request = axios.delete(`${config.endpoints.bff}/v1/coupons/${id}`)

  return (dispatch) => {
    return request
      .then((response) =>
        dispatch({
          type: DELETE_COUPON,
          payload: response.data
        })
      )
  }
}


export function saveCoupon(data) {
  try {
    let form = new FormData()
    for (let key in data) {
      if (validCouponFields.includes(key) || data.vendors.length !== 0) {
        form.append(key, data[key]);
      }
    }
  
    return (dispatch) => {
        let request = uploadImage(data.cuponImage)
  
        delete form.cuponImage
        !form.users && delete form.users
        return request.then(async (image) => {
          try {
            let response
            let imageForm = image && image.data 
            if(imageForm !== undefined ) {
              form.append("image", JSON.stringify(imageForm))
            }
            if (data._id) {
              delete form.vendors
              response = await axios.put(`${config.endpoints.bff}/v1/coupons/${data._id}`, form)
              if(response && response.data && response.data.url) window.location.href = response.data.url
            } else {
              response = await axios.post(`${config.endpoints.bff}/v1/coupons`, form)
              if(response && response.data && response.data.url) window.location.href = response.data.url
            }
            let formCopy = {...form}
            const obj = Object.assign(formCopy, response.data);
            dispatch({
              type: SAVE_COUPON,
              payload: obj
            })
      
            return response
          } catch (error) {
            console.error(error)
          }
        }
        )
    }
  } catch (error) {
    console.error(error)
  }
  
}

async function uploadImage(file) {
  if (file) {
    const form = new FormData()
    form.append('file', file)

    return axios.post(`${config.endpoints.bff}/v1/coupons/image`, form)
  } else {
    return {}
  }
}

export function getBussinesTypes() {
  const request = axios.get(`${config.endpoints.bff}/v1/bussinesType`)
  return (dispatch) =>
    request
      .then((response) =>
        dispatch({
          type: GET_BUSINESS_TYPES,
          payload: response.data
        })
      )
}

export function newCoupon() {
  const data = {
    code: null,
    amount: 0,
    minimum_amount: 0,
    discount_type: 'amount',
    description: '',
    date_expires: '',
    vendors: [],
    expanded: '',
    comunes: [],
    couponType: 'one-vendor',
    image:'',
    providers: [],
  }

  return {
    type: GET_COUPON,
    payload: data
  }
}
