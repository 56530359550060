import axios from 'axios/index'
import config from 'config'

export const GET_DEEPLINK = '[INVENTORY] GET DEEPLINK'

export function getDeeplinkRoute (routeLink) {
  const request = axios.get(`${config.endpoints.bff}/v1/deeplinks/generate?${routeLink}`)

  return (dispatch) =>
    request
      .then((response) =>
        dispatch({
          type: GET_DEEPLINK,
          payload: response.data
        })
      )
}

