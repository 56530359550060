import axios from 'axios/index'
import Axios from 'axios'
import { showMessage } from 'store/actions/fuse'
import config from 'config'
import { getByProp } from '../../../../../utils/country'

export const GET_VENDOR = '[E-COMMERCE APP] GET VENDOR'
export const SAVE_VENDOR = '[E-COMMERCE APP] SAVE VENDOR'
export const UPDATE_VENDOR = '[E-COMMERCE APP] UPDATE VENDOR'
export const DELETE_VENDOR = '[E-COMMERCE APP] DELETE VENDOR'
export const GET_CITIES = '[INVENTORY] GET CITIES'
export const GET_COMUNES = '[INVENTORY] GET COMUNES'
export const GET_PAYMENT_METHODS = '[INVENTORY] GET PAYMENT METHODS'
export const GET_NO_MAPPED_CATEGORIES = '[INVENTORY] GET CATEGORIES NO MAPPED'
export const GET_COUNTRIES = '[INVENTORY] GET COUNTRIES'
export const GET_CATEGORIES = '[INVENTORY] GET CATEGORIES'
export const UPDATE_CATEGORIES = '[INVENTORY] UPDATE CATEGORIES'
export const UPDATE_BRANDS = '[INVENTORY] UPDATE CATEGORIES'
export const GET_NO_MAPPED_BRANDS = '[INVENTORY] GET CATEGORIES NO MAPPED'
export const GET_BRANDS = '[INVENTORY] GET CATEGORIES'
export const GET_BUSSINES_TYPE = '[INVENTORY] GET BUSSINES TYPE'
export const CREATE_RIBBON = 'CREATE_RIBBON'
export const DELETE_RIBBON = 'DELETE_RIBBON'
export const GET_SHIPMENTS_GURU = ' GET_SHIPMENTS_GURU'
export const ACTIVE_SHIPMENTS_GURU = "ACTIVE_SHIPMENTS_GURU"
export const CREATE_SHIPMENTS_GURU = "CREATE_SHIPMENTS_GURU"
export const DELETE_SHIPMENTS_GURU = "DELETE_SHIPMENTS_GURU"

export function getVendor(vendorId) {
  const request = axios.get(`${config.endpoints.bff}/v1/vendors/${vendorId}`)

  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_VENDOR,
        payload: response.data
      })
    )
}

export function getCities() {
  const request = axios.get(`${config.endpoints.bff}/v1/cities`)

  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_CITIES,
        payload: response.data
      })
    )
}

export function getComunes() {
  const request = axios.get(`${config.endpoints.bff}/v1/comunes`)

  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_COMUNES,
        payload: response.data
      })
    )
}

export function getPaymentMethods(vendorCountryName) {
  const countryCode = getByProp('name', vendorCountryName, 'code')
  const headers = { countryCode }
  const request = axios.get(`${config.endpoints.bff}/v1/paymentmethods`, { headers })

  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_PAYMENT_METHODS,
        payload: response.data
      })
    )
}

export function getAllCategories(vendorId) {
  const request = axios.get(`${config.endpoints.bff}/v1/vendors/${vendorId}/mainCategories`)

  return (dispatch) =>
  request.then((response) =>
    dispatch({
      type: GET_CATEGORIES,
      payload: response.data
    })
  )
}

export function getNoMappedCategories(vendorId) {
  const request = axios.get(`${config.endpoints.bff}/v1/vendors/${vendorId}/map-categories`)

  return (dispatch) =>
  request.then((response) =>
    dispatch({
      type: GET_NO_MAPPED_CATEGORIES,
      payload: response.data
    })
  )
}

export function updateCategories(vendor) {
  const request = axios.post(`${config.endpoints.bff}/v2/mapCategoryVendors`, vendor)

  return (dispatch) =>
  request.then((response) =>
    dispatch({
      type: UPDATE_CATEGORIES,
      payload: response.data
    })
  )
}

export function getBrandsOfProvider(vendorId) {
  const request = axios.get(`${config.endpoints.bff}/v1/brands/findByProvider/${vendorId}`)

  return (dispatch) =>
  request.then((response) =>
    dispatch({
      type: GET_BRANDS,
      payload: response.data
    })
  )
}

export function getNoMappedBrands(vendorId) {
  const request = axios.get(`${config.endpoints.bff}/v1/vendors/${vendorId}/map-brands`)

  return (dispatch) =>
  request.then((response) =>
    dispatch({
      type: GET_NO_MAPPED_BRANDS,
      payload: response.data
    })
  )
}

export function updateBrands(vendor) {
  const request = axios.post(`${config.endpoints.bff}/v1/mapBrandVendors`, vendor)

  return (dispatch) =>
  request.then((response) =>
    dispatch({
      type: UPDATE_BRANDS,
      payload: response.data
    })
  )
}

const stringToSlug = (text) => {
  text = text.replace(/^\s+|\s+$/g, ''); 
  text = text.toLowerCase();

    text = text.replace(/[^a-z0-9 -]/g, '')
        .replace(/\s+/g, '-') 
        .replace(/-+/g, '-');

    return text;
}

const makeLogoSlug = (name) => {
  const time = (new Date()).getTime()

  return "p_" + stringToSlug(name) + "_" + time + ".png"
}

export function saveVendor(data, shouldUploadLogo) {
  const imageFromLogo = data
  return (dispatch) => {
    data.logo = shouldUploadLogo ? makeLogoSlug(data.name) : data.logo 
    let request = shouldUploadLogo ? uploadImage(data.imageLogo, data.logo) : Promise.resolve()
    delete imageFromLogo.imageLogo
    return request.then(async (image) => {
      let response
      if (data._id) {
        response = await axios.put(`${config.endpoints.bff}/v1/vendors/${data._id}`, data)
        dispatch(showMessage({ message: 'Proveedor actualizado' }))
      } else {
        response = await axios.post(`${config.endpoints.bff}/v1/vendors`, data)
        dispatch(showMessage({ message: 'Proveedor creado' }))
      }

      return dispatch({
        type: UPDATE_VENDOR,
        payload: Object.assign(imageFromLogo, response.data)
      })
    })
  }
}

async function uploadImage(file, name) {
  if (file) {
    const form = new FormData()
    form.append('file', file)
    form.append('name', name)

    return axios.post(`${config.endpoints.bff}/v1/vendors/assets/logo`, form)
  } else {
    return {}
  }
}

export function deleteVendor(id) {
  return (dispatch) => {
    const request = axios.delete(`${config.endpoints.bff}/v1/vendors/${id}`)
    return request
      .then((response) => {
        dispatch(showMessage({ message: 'Proveedor eliminado' }))

        return dispatch({
          type: DELETE_VENDOR,
          payload: response.data
        })
      })
      .catch(() => {
        dispatch(showMessage({ message: 'No se ha eliminado el proveedor' }))
      })
  }
}

export function getCountries() {
  const request = axios.get(`${config.endpoints.bff}/v1/countries`)

  return (dispatch) =>
  request.then((response) =>
    dispatch({
      type: GET_COUNTRIES,
      payload: response.data
    })
  )
}

export function newVendor() {
  const data = {
    name: '',
    rut: '',
    email: '',
    expanded: '',
    comunes: [],
    paymentMethods: [],
    bussinesTypeId: ''
  }

  return {
    type: GET_VENDOR,
    payload: data
  }
}

export function getBussinesType () {
  const request = axios.get(`${config.endpoints.bff}/v1/bussinesType`)
  return (dispatch) =>
    request
      .then((response) =>
        dispatch({
          type: GET_BUSSINES_TYPE,
          payload: response.data
        })
      )
}

export function saveRibbon(vendorId, ribbonObj) {
  return (dispatch) => {
    const request = axios.post(`${config.endpoints.bff}/v1/vendors/${vendorId}/ribbons`, ribbonObj)
    return request.then((response) => {
      dispatch(showMessage({ message: 'Etiqueta actualizada' }))
      return dispatch({
        type: CREATE_RIBBON,
        payload: {},
      })
    }).catch(() => {
      dispatch(showMessage({ message: 'No se ha podido actualizar la etiqueta, intente nuevamente.' }))
    })
  }
}

export function deleteRibbon(vendorId) {
  return (dispatch) => {
    const request = axios.delete(`${config.endpoints.bff}/v1/vendors/${vendorId}/ribbons`)
    return request.then((response) => {
      dispatch(showMessage({ message: 'Etiqueta actualizada' }))

      return dispatch({
        type: DELETE_RIBBON,
        payload: {},
      })
    }).catch(() => {
      dispatch(showMessage({ message: 'No se ha podido actualizar la etiqueta, intente nuevamente.' }))
    })
  }
}

export function getShipmentsGuru (vendorId) {
  const request = axios.get(`${config.endpoints.bff}/v1/dispatchtimes?providerId=${vendorId}`)
  return (dispatch) =>
    request
      .then((response) =>
        dispatch({
          type: GET_SHIPMENTS_GURU,
          payload: response.data
        })
      )
}

export function activeShipmentsGuruProvider (vendorId, type) {
  const request = axios.put(`${config.endpoints.bff}/v1/dispatchtimes/${type}?providerIds=${vendorId}`)
  return (dispatch) =>
    request
      .then((response) =>
        dispatch({
          type: ACTIVE_SHIPMENTS_GURU,
          payload: response.data
        })
  )
}

export function createShipmentsGuruProvider (
  vendorId, 
  currency, 
  nameRule, 
  ordersCutMax, 
  comunes, 
  cutHour, 
  cutMinutes, 
  checkboxDay,
) {
  const request = axios.post(`${config.endpoints.bff}/v1/dispatchtimes`, {
    "providerId": vendorId,
    "countryCode": currency,
    "ordersCutMax": ordersCutMax,
    "rule": {
      "name": nameRule,
      "days": checkboxDay,
      "deliveryZonesIds": comunes,
      "cutHour": cutHour,
      "cutMinutes": cutMinutes,
      "dispatchTimeStart":10,
      "dispatchTimeEnd":  20
    }},
    { params: { "providerId": vendorId } }
  )
  return (dispatch) =>
    request
      .then((response) =>
        dispatch({
          type: CREATE_SHIPMENTS_GURU,
          payload: response.data
        })
  )
}

export function editShipmentsGuruProvider (
  ruleId,
  vendorId, 
  currency, 
  nameRule, 
  ordersCutMax, 
  comunes, 
  cutHour, 
  cutMinutes, 
  checkboxDay,
) {
  const request = axios.put(`${config.endpoints.bff}/v1/dispatchtimes/${ruleId}`,{
    "providerId": vendorId,
    "countryCode": currency,
    "ordersCutMax": ordersCutMax,
    "rule": {
      "name": nameRule,
      "days": checkboxDay,
      "deliveryZonesIds": comunes,
      "cutHour": cutHour,
      "cutMinutes": cutMinutes,
      "dispatchTimeStart": 10,
      "dispatchTimeEnd": 20,
    }},
    { params: { "providerId": vendorId } }
)
  return (dispatch) =>
    request
      .then((response) =>
        dispatch({
          type: CREATE_SHIPMENTS_GURU,
          payload: response.data
        })
  )
}

export function deleteShipmentsGuruProvider (idRule) {
  const request = axios.delete(`${config.endpoints.bff}/v1/dispatchtimes/${idRule}`)
  return (dispatch) =>
    request
      .then((response) =>
        dispatch({
          type: DELETE_SHIPMENTS_GURU,
          payload: response.data
        })
      )
}

  




export async function getShippingCost({vendorId}) {
  try {
    const {data} = await axios.get(`${config.endpoints.bff}/v1/shippingcost/${vendorId}`)
    return data.shippingCost
  } catch(error) {
    console.error(error);
  }
}

export async function updateShippingCost({vendorId, shippingCost}) {
  try {
    const {data} = await axios.post(`${config.endpoints.bff}/v1/shippingCost`, {vendorId, shippingCost})
    return data
  } catch(error) {
    console.error(error);
  }
}
