/**
 * Authorization Roles
 */
const authRoles = {

  root: ['root'],
  admin: ['admin', 'root'],
  staff: ['admin', 'staff', 'root'],
  user: ['admin', 'staff', 'user', 'root'],
  distributor: ['admin', 'staff', 'distributor', 'user', 'root'],
  onlyUser: ['admin', 'user'],
  onlyGuest: ['guest']
}

export default authRoles
