import React from 'react'
import { Redirect } from 'react-router-dom'
import { FuseLoadable } from '@fuse'
import { authRoles } from 'auth'

export const SalesAppConfig = {
  settings: {
    layout: {}
  },
  auth: authRoles.user,
  routes: [
    {
      path: process.env.PUBLIC_URL + '/salesman/:salesmanId',
      component: FuseLoadable({
        loader: () => import('./salesman/Salesman')
      })
    },
    {
      path: process.env.PUBLIC_URL + '/salesman',
      component: FuseLoadable({
        loader: () => import('./salesmen/Salesmen')
      })
    },
    {
      path: '/salesman',
      component: () => <Redirect to="/salesman" />
    }
  ]
}
