import { FuseLoadable } from '@fuse'

export default [
  {
    path: process.env.PUBLIC_URL + '/users-management/roles/:roleId',
    component: FuseLoadable({
      loader: () => import('./role/Role')
    })
  },
  {
    path: process.env.PUBLIC_URL + '/users-management/roles',
    component: FuseLoadable({
      loader: () => import('./list/RolesList')
    })
  }
]
