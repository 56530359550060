import axios from 'axios/index'
import { FuseUtils } from '@fuse'
import { showMessage } from 'store/actions/fuse'
import config from 'config'

export const GET_CATEGORY = '[INVENTORY] GET CATEGORY'
export const GET_BUSSINES_TYPE = '[INVENTORY] GET BUSSINES TYPE'
export const SAVE_CATEGORY = '[INVENTORY] SAVE CATEGORY'
export const MASS_MAP = '[INVENTORY] MASS MAP'
export const OPEN_DIALOG_CATEGORY = '[INVENTORY] OPEN CATEGORY'
export const OPEN_NEW_DIALOG_CATEGORY = '[INVENTORY] OPEN NEW CATEGORY'
export const GET_COUNTRIES = '[INVENTORY] GET COUNTRIES'

export function getCategory (params) {
  const request = axios.get(`${config.endpoints.bff}/v1/categories/${params.categoryId}`)

  return (dispatch) =>
    request
      .then((response) =>
        dispatch({
          type: GET_CATEGORY,
          payload: response.data
        })
      )
}

export function getBussinesType () {
  const request = axios.get(`${config.endpoints.bff}/v1/bussinesType`)

  return (dispatch) =>
    request
      .then((response) =>
        dispatch({
          type: GET_BUSSINES_TYPE,
          payload: response.data
        })
      )
}

export function saveCategory (data) {
  return (dispatch) => {
    const request = axios.put(`${config.endpoints.bff}/v1/categories/${data._id}`, data)
    return request.then((response) => {
      dispatch(showMessage({ message: 'Categoría actualizada' }))

      return dispatch({
        type: SAVE_CATEGORY,
        payload: response.data
      })
    }
    )
  }
}

export function massMap (data) {
  return (dispatch) => {
    const request = axios.post(`${config.endpoints.bff}/v2/mapCategoryVendors`, data)
    return request.then((response) => {
      dispatch(showMessage({ message: 'Mapeo masivo realizado' }))

      return dispatch({
        type: MASS_MAP,
        payload: response.data
      })
    }
    )
  }
}

export function createCategory (data) {
  return (dispatch) => {

    const request = axios.post(`${config.endpoints.bff}/v1/categories`, data)
    return request.then((response) => {
      dispatch(showMessage({ message: 'Categoría creada' }))

      return dispatch({
        type: SAVE_CATEGORY,
        payload: response.data
      })
    }
    )
  }
}

export function openDialogCategory (show, item) {
  return {
    type: OPEN_DIALOG_CATEGORY,
    dialogCreate: show,
    category: item
  }
}

export function openNewDialogCategory (show) {
  return {
    type: OPEN_NEW_DIALOG_CATEGORY,
    newDialogCreate: show
  }
}

export function newCategory () {
  const data = {
    id: FuseUtils.generateGUID(),
    name: '',
    handle: '',
    description: '',
    categories: [],
    tags: [],
    images: [],
    priceTaxExcl: 0,
    priceTaxIncl: 0,
    taxRate: 0,
    comparedPrice: 0,
    quantity: 0,
    sku: '',
    width: '',
    height: '',
    depth: '',
    weight: '',
    extraShippingFee: 0,
    active: true
  }

  return {
    type: GET_CATEGORY,
    payload: data
  }
}

export function getCountries() {
  const request = axios.get(`${config.endpoints.bff}/v1/countries`)

  return (dispatch) =>
  request.then((response) =>
    dispatch({
      type: GET_COUNTRIES,
      payload: response.data
    })
  )
}
