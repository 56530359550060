import * as Actions from '../actions'

const initialState = {
  data: []
}

const brandReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_BRAND:
      {
        return {
          ...state,
          data: action.payload
        }
      }
    case Actions.SAVE_BRAND:
      {
        return {
          ...state,
          data: action.payload
        }
      }
    default:
      {
        return state
      }
  }
}

export default brandReducer
