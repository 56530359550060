import * as Actions from '../actions'

const initialState = {
  data: []
}

const promotionReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_PROMOTION:
    {
      return {
        ...state,
        data: action.payload
      }
    }
    case Actions.GET_BUSINESS_TYPES:
    {
      return {
        ...state,
        data: action.payload
      }
    }
    case Actions.SAVE_PROMOTION:
    {
      return {
        ...state,
        data: action.payload
      }
    }
    default:
    {
      return state
    }
  }
}

export default promotionReducer
