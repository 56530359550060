import axios from 'axios/index'
import { FuseUtils } from '@fuse'
import { showMessage } from 'store/actions/fuse'
import config from 'config'

export const GET_PRODUCT = '[E-COMMERCE APP] GET PRODUCT'
export const GET_PRODUCT_COUNTRY = '[E-COMMERCE APP] GET PRODUCT COUNTRY'
export const DELETE_IMAGE_PRODUCT = '[E-COMMERCE APP] DELETE IMAGE PRODUCT'
export const REORDER_IMAGE_PRODUCT = '[E-COMMERCE APP] REORDER IMAGE PRODUCT'
export const SAVE_PRODUCT = '[E-COMMERCE APP] SAVE PRODUCT'

export function getProduct (params) {
  const request = axios.get(`${config.endpoints.bff}/v1/products/${params.productId}`)

  return (dispatch) =>
    request
      .then((response) =>
        dispatch({
          type: GET_PRODUCT,
          payload: response.data
        })
      )
}

export function getProductCountry (vendorId) {

  try {
    const request = axios.get(`${config.endpoints.bff}/v1/vendors/${vendorId}`)
  
    return (dispatch) =>
      request
        .then((response) =>
          dispatch({
            type: GET_PRODUCT_COUNTRY,
            payload: response.data.country.name
          })
        )
    
  } catch (error) {
    return (dispatch) =>
      dispatch({
        type: GET_PRODUCT_COUNTRY,
        payload: ""
      })
  }
}

export function deleteImageProduct (productId, mediaId) {
  const request = axios.delete(`${config.endpoints.bff}/v1/products/${productId}/medias/${mediaId}`)

  return (dispatch) =>
    request
      .then((response) => {
        dispatch(showMessage({ message: 'Imagen eliminada' }))

        return dispatch({
          type: DELETE_IMAGE_PRODUCT,
          payload: response.data
        })
      }, () => {
        dispatch(showMessage({ message: 'Ha ocurrido un problema' }))
      })
}

export function reorderImageProduct (productId, mediaId, position) {
  const request = axios.put(`${config.endpoints.bff}/v1/products/${productId}/medias/${mediaId}`, {
    position })

  return (dispatch) =>
    request
      .then((response) => {
      dispatch(showMessage({ message: 'Orden actualizado' }))

      return dispatch({
        type: REORDER_IMAGE_PRODUCT,
        payload: response.data
      })
    }, () => {
      dispatch(showMessage({ message: 'Ha ocurrido un problema' }))
    })
}

export function saveProduct (data) {
  if (data.imageProduct) delete data.imageProduct

  return (dispatch) => {
    let promo
    const { price, offerPrice } = data
    const priceInt = parseInt(price)
    const offerPriceInt = parseInt(offerPrice)
    let promotionType = 'no_promotion'

    if (data.promoImage) promo.image = uploadImage(data.promoImage)
    if (offerPriceInt && offerPriceInt > 0 && offerPriceInt < priceInt) promotionType = 'directOfferPrice'
    
    data.promotionalDynamicType = promotionType

    const request = axios.put(`${config.endpoints.bff}/v1/products/${data._id}`, data)
    return request.then((response) => {
      dispatch(showMessage({ message: 'Producto actualizado' }))

      return dispatch({
        type: SAVE_PRODUCT,
        payload: response.data
      })
    }, () => {
      dispatch(showMessage({ message: 'Ha ocurrido un problema' }))
    })
  }
}

export default async function uploadProductMultiplesImage (file, productId) {

  if(!file) return {}

  const form = new FormData()
  form.append('file', file)
  return axios.post(`${config.endpoints.bff}/v1/products/${productId}/medias`, form)
}

async function uploadImage (file) {
  const form = new FormData()
  form.append('file', file)
  return axios.post(`${config.endpoints.bff}/v1/products/image`, form)
}

export function newProduct () {
  const data = {
    id: FuseUtils.generateGUID(),
    name: '',
    handle: '',
    description: '',
    categories: [],
    tags: [],
    images: [],
    priceTaxExcl: 0,
    priceTaxIncl: 0,
    taxRate: 0,
    comparedPrice: 0,
    quantity: 0,
    sku: '',
    width: '',
    height: '',
    depth: '',
    weight: '',
    extraShippingFee: 0,
    active: true
  }

  return {
    type: GET_PRODUCT,
    payload: data
  }
}
