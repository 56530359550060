import axios from 'axios'
import config from 'config'
import { showMessage } from 'store/actions/fuse'

export const GET_WHITELIST = '[E-COMMERCE APP] GET WHITELIST'
export const LOADING_ON = '[E-COMMERCE APP] LOADING TRUE'
export const LOADING_OFF = '[E-COMMERCE APP] LOADING FALSE'
export const CLEAR_TABLE = '[E-COMMERCE APP] CLEAR TABLE'

export function getBlocksLists(providerId) {
  return async (dispatch) => {
    dispatch({ type: LOADING_ON })
    let endpoint = `/v1/vendors/${providerId}/users/block`
    const response = await axios.get(`${config.endpoints.bff}${endpoint}`)    

    dispatch({ type: GET_WHITELIST, payload: response.data })
    return response.data
  }
}

export function updateBlockList(providerId, ruts, behavior) {
  return async (dispatch) => {
    dispatch({ type: LOADING_ON })
    
    let endpoint = `/v1/vendors/${providerId}/users/block?behavior=${behavior}`
    const response = await axios.put(`${config.endpoints.bff}${endpoint}`, { ruts })    

    if (response.status === 200) {
      dispatch({ type: GET_WHITELIST, payload: response.data })
      return response.data
    } 
    return null
  }
}

export function clearTable() {
  return (dispatch) => {
    return dispatch({ type: CLEAR_TABLE })
  }
}
