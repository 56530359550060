import { combineReducers } from "redux";
import brands from "./brands.reducer";
import brand from "./brand.reducer";
import categories from "./categories.reducer";
import category from "./category.reducer";
import products from "./products.reducer";
import product from "./product.reducer";
import promotions from "./promotions.reducer";
import promotion from "./promotion.reducer";
import coupons from "./coupons.reducer";
import coupon from "./coupon.reducer";
import vendors from "./vendors.reducer";
import vendor from "./vendor.reducer";
import blacklist from "./blacklist.reducer";
import deeplinks from "./deeplinks.reducer"
import whitelist from "./whitelist.reducer";

const reducer = combineReducers({
  brands,
  brand,
  categories,
  category,
  products,
  product,
  coupons,
  coupon,
  promotions,
  promotion,
  vendors,
  vendor,
  blacklist,
  deeplinks,
  whitelist
});

export default reducer;
