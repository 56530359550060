import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import { withStyles } from '@material-ui/core/styles/index'
import classNames from 'classnames'
import {
  Avatar, Button, Icon, ListItemIcon, ListItemText,
  Popover, MenuItem, Typography, FormControl, InputLabel,
  Select, OutlinedInput
} from '@material-ui/core'
import { connect } from 'react-redux'
import * as quickPanelActions from 'main/quickPanel/store/actions'
import * as authActions from 'auth/store/actions'
import * as vendorsActions from 'main/content/inventory/store/actions'
import { authRoles, Securitize } from 'auth'
import { bindActionCreators } from 'redux'
import { FuseAnimate } from '@fuse'
import { Link } from 'react-router-dom'
import * as _ from 'lodash'
import { countryPe } from '../utils/country'

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginTop: 10,
    marginBottom: 10
  },
  seperator: {
    width: 1,
    height: 64,
    backgroundColor: theme.palette.divider
  },
  form: {
    margin: theme.spacing(1),
    minWidth: 'max-content',    
  }
})

class MainToolbar extends PureComponent {
  state = {
    userMenu: null,
    labelWidth: 0,
    form: {
      providerId: null,
      countryCode: null
    },
  }

  componentDidMount = () => {
    if (this.props.user) {
      const { user } = this.props
      if (user.role.alias !== 'guest' && !user.providerId) {
        this.props.getVendors()        
        this.props.getCountries()
      }
    }
    const providerId = this.state.form.providerId !== this.props.vendor ? this.props.vendor :  null
    const countryCode = localStorage.getItem('selectedCountry')
    
    const form = {}
    
    if (providerId) form.providerId = providerId

    if (countryCode) form.countryCode = countryCode
        
    this.setState({
      form
    })
    

  }

  userMenuClick = event => {
    this.setState({ userMenu: event.currentTarget })
  }

  userMenuClose = () => {
    this.setState({ userMenu: null })
  }

  setRef = (ref) => {
    const DOMElement = ReactDOM.findDOMNode(ref)
    if (DOMElement && !this.state.labelWidth) {
      const labelWidth = DOMElement.offsetWidth
      this.setState({
        labelWidth
      })
    }
  }

  handleChange = (event) => {
    this.setState({ form: _.set({ ...this.state.form }, event.target.name, event.target.type === 'checkbox' ? event.target.checked : event.target.value) })
  }

  validateIfExistVendorCurrency = (vendorCurrency) => vendorCurrency && vendorCurrency.currency

  getCurrencyVendor = (vendors, vendorId) => {
    if(vendorId === 'undefined' || vendorId === 'null') {
      localStorage.setItem('vendorCurrency','{"currency":"CLP","currencyName":"Peso","currencySymbol":"$"}')
      return
    }
    if(vendors) {
      const vendorCurrencyWithProviderId = vendors.find(vendor => vendor._id === vendorId)
      localStorage.setItem('vendorCurrency', JSON.stringify(this.validateIfExistVendorCurrency(vendorCurrencyWithProviderId)))
    }
  }

  handleChangeVendor = (event) => {
    const {  vendors } = this.props
    
    this.setState({ form: _.set({ ...this.state.form }, event.target.name, event.target.type === 'checkbox' ? event.target.checked : event.target.value) })
    this.props.changeVendor(event.target.value)
    const vendorCurrencyWithProviderId = vendors.find(vendor => vendor._id === event.target.value)
    
    localStorage.setItem('selectedVendor', JSON.stringify(vendorCurrencyWithProviderId))
    window.location.reload()
  }

  handleChangeCountry = (event) => {
    const { countries } = this.props

    const  selectedCountry = event.target.value
    this.setState({ form: _.set({ ...this.state.form }, event.target.name, selectedCountry) })    
    this.props.changeCountry(selectedCountry)
    localStorage.setItem('selectedCountry', selectedCountry)
    window.location.reload()
  }

  render() {
    const { classes, user, logout, vendors, countries } = this.props
    const { userMenu, form } = this.state
    const currentValue = form.providerId || "DEFAULT";
    const currentValueCountry = form.countryCode || "DEFAULT";
    localStorage.setItem('roleUser', user.role.name)
    localStorage.setItem('providerId', user.role.name === 'Super administrador' ? localStorage.getItem('vendor') : user.providerId)
    if (localStorage.getItem('vendor') === 'DEFAULT') {
      localStorage.removeItem('vendor');
    }
    const vendorId = localStorage.getItem('providerId')
    this.getCurrencyVendor(vendors, vendorId)
    return (
      <div className={classNames(classes.root, 'flex flex-row')}>

        {!user.providerId && (<div className="flex flex-1 ml-16">
          <Securitize auth={authRoles.root}>
            <FormControl variant="outlined" className={classNames(classes.form, 'mt-8 mb-16')}>
              <InputLabel
                shrink
                ref={ref => {
                  this.InputLabelRef = ref
                  this.setRef(ref)
                }}
                htmlFor="countryId-select">
                País
              </InputLabel>

              <Select
                native
                value={currentValueCountry}
                onChange={this.handleChangeCountry}
                input={
                  <OutlinedInput
                    labelWidth={this.state.labelWidth}
                    name="countryCode"
                    id="countryId-select"
                  />
                }
              >
                <option key="none" value="DEFAULT">Todos los paises</option>
                ({countries && countries.map(
                  ({_id, code, name}) =>
                  <option key={_id} value={code}>{name}</option>
                )})
              </Select>
            </FormControl>
          </Securitize>
          <Securitize auth={authRoles.root}>
          <FormControl variant="outlined" className={classNames(classes.form, 'mt-8 mb-16')} fullWidth>              
              <InputLabel                
                shrink
                ref={ref => {
                  this.InputLabelRef = ref
                  this.setRef(ref)
                }}
                htmlFor="providerId-select">
                Proveedor
              </InputLabel>

              <Select
                native
                value={currentValue}
                onChange={this.handleChangeVendor}
                input={
                  <OutlinedInput
                    labelWidth={this.state.labelWidth}
                    name="providerId"
                    id="providerId-select"
                  />
                }
              >
                <option key="none" value="DEFAULT">Todos los proveedores</option>
                ({vendors && vendors.map(
                  (vendor) =>
                    <option key={vendor._id} value={vendor._id}>{vendor.name}</option>
                )})
              </Select>
            </FormControl>
          </Securitize>
        </div>)}

        <div className="md:flex md:flex-1 sm:hidden"></div>

        <div className="flex">
          <FuseAnimate delay={300}>
            <Button className="h-64 header-content-user" onClick={this.userMenuClick}>
              {user && user.photoURL
                ? (
                  <Avatar className="" alt="user photo" src={user.photoURL} />
                )
                : (
                  <Avatar className="" src="assets/images/avatars/profile.png">
                    {user && user.name}
                  </Avatar>
                )
              }

              <div className="hidden md:flex flex-col ml-12 items-start">
                <Typography component="span" className="normal-case font-600 flex">
                  {user && user.name}
                </Typography>
                <Typography className="text-11 text-red capitalize" color="textSecondary">
                  {user && user.role && user.role.name}
                </Typography>
              </div>

              <Icon className="text-16 ml-12 hidden sm:flex" variant="action">keyboard_arrow_down</Icon>
            </Button>
          </FuseAnimate>

          <Popover
            open={Boolean(userMenu)}
            anchorEl={userMenu}
            onClose={this.userMenuClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            classes={{
              paper: 'py-8'
            }}
          >
            <React.Fragment>
              <MenuItem component={Link} to={`${process.env.PUBLIC_URL}/profile`} onClick={this.userMenuClose}>
                <ListItemIcon>
                  <Icon>account_circle</Icon>
                </ListItemIcon>
                <ListItemText className="pl-0" primary="Mi perfil" />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  logout()
                  this.userMenuClose()
                }}
              >
                <ListItemIcon>
                  <Icon>exit_to_app</Icon>
                </ListItemIcon>
                <ListItemText className="pl-0" primary="Cerrar sesión" />
              </MenuItem>
            </React.Fragment>
          </Popover>
        </div>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    toggleQuickPanel: quickPanelActions.toggleQuickPanel,
    logout: authActions.logoutUser,
    getVendors: vendorsActions.getVendors,
    changeVendor: vendorsActions.changeVendor,
    getCountries: vendorsActions.getCountries,
    changeCountry: vendorsActions.changeCountry
  }, dispatch)
}

function mapStateToProps({ auth, stores }) {

  return {
    user: auth.user,
    vendors: stores && stores.vendors && stores.vendors.data,
    vendor: stores && stores.vendors && stores.vendors.vendor,
    countries: stores && stores.vendors && stores.vendors.countries,
    selectedCountry: stores && stores.vendors && stores.vendors.countryCode
  }
}

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(MainToolbar))
