export const countryCl = 'CL'
export const countryPe = 'PE'
export const countryMx = 'MX'
export const defaultDni = 'DNI'

export const territorialNames = {
  [countryCl]: { region: "Región", province: "", commune: "Comuna" },
  [countryPe]: { region: "Departamento", province: "Provincia", commune: "Distrito" },
  [countryMx]: { region: "Estado", province: "Delegación", commune: "Colonia" },
}
export const countryClName = 'Chile';
export const countryPeName ='Perú';
export const countryMxName = 'México';

export const usernameMaxLength = {
  [countryCl]: 9,
  [countryPe]: 11,
  [countryMx]: 10,
}

export const getByProp = (searchProp, searchValue, propToReturn) => {
  const countries = [
    { code: 'CL', name: 'Chile' },
    { code: 'PE', name: 'Perú' },   
    { code: 'MX', name: 'México' },
  ]

  const selectedCountry = countries.find((country) => country[searchProp] === searchValue)

  if(!selectedCountry) return ''
  return selectedCountry[propToReturn]
}

export const usernameByCountryname = (countryName) => {
  const usernames = {
    'Chile': 'RUT',
    'Perú': 'RUC o DNI',
    'México': 'Teléfono',
  }

  return usernames[countryName] || 'DNI'
}

export const validateUsernameLength = (countryName, userName) => {
  if (!userName) return false;

  switch (countryName) {
    case countryClName:
      return isInRange(userName.length, 8, 9);

    case countryPeName:
      return [8, 11].includes(userName.length);
      
    case countryMxName:
      return userName.length === 10;
  
    default:
      return false
  }
}

export const validateUsernameByCountry = (countryCode, username) => {
  if (!username) return false;
  const validateLength = validateUsernameLengthByCountryCode(countryCode, username)
  const validateFormat = validateUsernameValueByCountry(countryCode, username)
  return validateLength && validateFormat
}

export const validateUsernameLengthByCountryCode = (countryCode, userName) => {
  switch (countryCode) {
    case countryCl:
      return isInRange(userName.length, 8, 9);

    case countryPe:
      return [8, 11].includes(userName.length);
      
    case countryMx:
      return userName.length === 10;
  
    default:
      return false
  }
}

export const validateUsernameValueByCountry = (countryCode, value) => {
  const onlyDigits = /^\d+$/
  switch(countryCode) {
    case countryCl:
      const rut = value.slice(0, value.length - 1);
      const verificatorDigit = value[value.length - 1];
      const isRutNumeric = onlyDigits.test(rut);
      const isVerificatorValid = onlyDigits.test(verificatorDigit) || verificatorDigit === 'K' || verificatorDigit === 'k';

      return isRutNumeric && isVerificatorValid;
    case countryPe:
      return onlyDigits.test(value)
    case countryMx:
      return onlyDigits.test(value)
    default:
      return false;
  }
}

const isInRange = (value, min, max) => {
  return value >= min && value <= max;
}