import React from 'react'
import { Redirect } from 'react-router-dom'
import { FuseLoadable } from '@fuse'
import { authRoles } from 'auth'

export const NotificationsAppConfig = {
  notifications: {
    layout: {}
  },
  auth: authRoles.admin,
  routes: [
    {
      path: process.env.PUBLIC_URL + '/notifications/app',
      component: FuseLoadable({
        loader: () => import('./app/App')
      })
    },
    {
      path: process.env.PUBLIC_URL + '/notifications',
      component: () => <Redirect to="/notifications/app" />
    }
  ]
}
