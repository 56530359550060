import * as Actions from '../actions'

const initialState = {
  data: [],
  loading: false,
}

const whitelistReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_WHITELIST: {
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    }
    case Actions.LOADING_ON: {
      return {
        ...state,
        loading: true,
      }
    }
    case Actions.LOADING_OFF: {
      return {
        ...state,
        loading: false,
      }
    }
    case Actions.CLEAR_TABLE: {
      return {
        ...state,
        data: [],
      }
    }
    default: {
      return state
    }
  }
}

export default whitelistReducer
