import { FuseLoadable } from '@fuse'

export const HelpAppConfig = {
  settings: {
    layout: {}
  },
  routes: [
    {
      path: process.env.PUBLIC_URL + '/help',
      component: FuseLoadable({
        loader: () => import('./Help')
      })
    }
  ]
}
