import { FuseLoadable } from "@fuse";

export default [
  {
    path: process.env.PUBLIC_URL + "/users-management/users/:userId",
    component: FuseLoadable({
      loader: () => import("./user/User"),
    }),
  },
  {
    path: process.env.PUBLIC_URL + "/users-management/users",
    component: FuseLoadable({
      loader: () => import("./list/UsersList"),
    }),
  },
];
