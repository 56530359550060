import axios from 'axios'
import jwtDecode from 'jwt-decode'
import FuseUtils from '@fuse/FuseUtils'
import config from 'config'

class jwtService extends FuseUtils.EventEmitter {
  init () {
    this.setInterceptors()
    this.handleAuthentication()
  }

  setInterceptors = () => {
    axios.interceptors.response.use(response => {
      return response
    }, err => {
      return new Promise((resolve, reject) => {
        if ((err && err.response) && (err.response.status === 401 && err.config && !err.config.__isRetryRequest)) {
          // if you ever get an unauthorized response, logout the user
          this.emit('onAutoLogout', 'Acceso inválido')
          this.setSession(null)
        }
        throw err
      })
    })
  }

  handleAuthentication = () => {
    let access_token = this.getAccessToken()

    if (!access_token) {
      return
    }

    if (this.isAuthTokenValid(access_token)) {
      this.setSession(access_token)
      this.emit('onAutoLogin', true)
    } else {
      this.setSession(null)
      this.emit('onAutoLogout', 'La sessión ha expirado')
    }
  }

  createUser = (data) => {
    return new Promise((resolve, reject) => {
      axios.post(`${config.endpoints.bff}/v1/users`, data)
        .then(response => {
          if (response.data.user) {
            this.setSession(response.data.access_token)
            resolve(response.data.user)
          } else {
            reject(response.data.error)
          }
        })
    })
  }

  signInWithEmailAndPassword = (email, password) => {
    return new Promise((resolve, reject) => {
      axios.post(`${config.endpoints.bff}/v1/users/login`, {
        username: email,
        password
      }).then(response => {
        if (response.data.user) {
          this.setSession(response.data.access_token)
          resolve(response.data.user)
        } else {
          reject(response.data.error)
        }
      })
      .catch(reject)
    })
  }

  signInWithToken = () => {
    return new Promise((resolve, reject) => {
      axios.get(`${config.endpoints.bff}/v1/users/access_token`, {
        data: {
          access_token: this.getAccessToken()
        }
      })
        .then(response => {
          if (response.data.user) {
            this.setSession(response.data.access_token)
            resolve(response.data.user)
          } else {
            reject(response.data.error)
          }
        })
    })
  }

  updateUserData = (user) => {
    return axios.put(`${config.endpoints.bff}/v1/users`, {
      user: user
    })
  }

  setSession = access_token => {
    if (access_token) {
      window.localStorage.setItem('jwt_access_token', access_token)
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token
    } else {
      window.localStorage.removeItem('jwt_access_token')
      delete axios.defaults.headers.common['Authorization']
    }
  }

  logout = () => {
    this.clearService();
    this.setSession(null)
  }

  clearService = () => {
    axios.interceptors.request.handlers = [];
    localStorage.clear()
    window.location.reload();
  }

  isAuthTokenValid = access_token => {
    if (!access_token) {
      return false
    }
    const decoded = jwtDecode(access_token)
    const currentTime = Date.now() / 1000
    if (decoded.exp < currentTime) {
      console.warn('access token expired')
      return false
    } else {
      return true
    }
  }

  getAccessToken = () => {
    return window.localStorage.getItem('jwt_access_token')
  }
}

const instance = new jwtService()

export default instance
