const config = {
  endpoints: {
    bff:
      process.env.ENDPOINT_BFF ||
      process.env.REACT_APP_ENDPOINT_BFF ||
      "http://localhost:5000",
    aws_images: process.env.REACT_APP_AWS_IMAGE,
    bff_analytics: process.env.REACT_APP_ENDPOINT_BFF_ANALYTICS,
    bff_public: process.env.REACT_APP_BFF_PUBLIC,
  },
  api_google_key: process.env.REACT_APP_API_GOOGLE_KEY,
};

export default config;
