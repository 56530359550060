import * as Actions from '../actions'

const initialState = {
  data: []
}

const productReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_PRODUCT:
    {
      return {
        ...state,
        data: action.payload
      }
    }
    case Actions.GET_PRODUCT_COUNTRY:
    {
      return {
        ...state,
        productCountryName: action.payload
      }
    }
    case Actions.SAVE_PRODUCT:
    {
      return {
        ...state,
        data: action.payload
      }
    }
    case Actions.DELETE_IMAGE_PRODUCT:
    {
      return {
        ...state,
        data: action.payload
      }
    }
    case Actions.REORDER_IMAGE_PRODUCT:
    {
      return {
        ...state,
        data: action.payload
      }
    }
    default:
    {
      return state
    }
  }
}

export default productReducer
